<template>
  <v-dialog v-model="dialog" persistent max-width="600px" width="100vw">
    <template #activator="{ on }">
      <base-button
        v-if="$vuetify.breakpoint.smAndDown"
        v-on="on"
        icon
        :disabled="disabled"
      >
        <v-icon>mdi-account-plus</v-icon>
      </base-button>

      <base-button v-else v-on="on" class="mx-1" :disabled="disabled">
        <v-row class="align-center">
          <v-col cols="1">
            <v-icon>mdi-account-plus-outline</v-icon>
          </v-col>
          <v-col>
            {{ $t("users.inviteUser") }}
          </v-col>
        </v-row>
      </base-button>
    </template>

    <base-card :title="$t('users.inviteUser')">
      <base-renderless-dialog>
        <template #default="{ openDialog }">
          <ValidationObserver v-slot="{ handleSubmit }">
            <v-form
              autocomplete="on"
              @submit.prevent="
                handleSubmit(() => next(() => openDialog(newUser)))
              "
            >
              <v-card-text>
                <v-container fluid>
                  <v-row no-gutters>
                    <v-col>
                      <ValidationProvider
                        v-slot="{ errors }"
                        name="email"
                        rules="required|email"
                        ref="validation"
                      >
                        <v-text-field
                          v-model="newUser.email"
                          :error-messages="errors"
                          :label="$t('common.email')"
                          autocomplete="email"
                          name="email"
                          prepend-icon="mdi-account"
                          required
                          type="email"
                        />
                      </ValidationProvider>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <base-button class="px-4" outlined text @click="dialog = false">
                  {{ $t("common.cancel") }}
                </base-button>
                <v-spacer />
                <base-button class="px-6" type="submit">
                  {{ $t("common.next") }}
                </base-button>
              </v-card-actions>
            </v-form>
          </ValidationObserver>
        </template>

        <template #dialog="{ data, listeners }">
          <invitation-permission-dialog
            :user="data"
            v-on="listeners"
            @success="onSuccess"
            is-new
          />
        </template>
      </base-renderless-dialog>
    </base-card>
  </v-dialog>
</template>

<script>
import InvitationPermissionDialog from "./InvitationPermissionDialog";
import { mapGetters } from "vuex";

export default {
  name: "InviteUserDialog",
  components: {
    InvitationPermissionDialog,
  },
  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      dialog: false,
      newUser: {
        email: "",
      },
    };
  },
  computed: {
    ...mapGetters("auth", ["getUserEmail"]),
  },
  methods: {
    next(openNextCallback) {
      if (this.newUser.email === this.getUserEmail) return;
      this.dialog = false;
      openNextCallback();
    },
    onSuccess() {
      this.$refs.validation.reset();
      this.newUser = {
        email: "",
      };
    },
  },
};
</script>

<style scoped></style>
