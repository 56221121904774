<template>
  <v-dialog v-model="dialog" max-width="600px" width="100vw">
    <template #activator="{ on }">
      <base-button
        v-if="$vuetify.breakpoint.smAndDown"
        v-on="on"
        icon
        :disabled="getIsProvidingService"
      >
        <v-icon>mdi-face-agent</v-icon>
      </base-button>

      <base-button
        v-else
        v-on="on"
        class="mx-1"
        :disabled="getIsProvidingService"
      >
        <v-row class="align-center">
          <v-col cols="1">
            <v-icon> mdi-face-agent</v-icon>
          </v-col>
          <v-col>
            {{ $t("users.manageDealers") }}
          </v-col>
        </v-row>
      </base-button>
    </template>

    <base-card :title="$t('users.manageDealers')">
      <v-form
        :disabled="loading"
        ref="form"
        v-model="valid"
        @submit.enter.prevent="validate"
      >
        <v-card-text>
          <v-container>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="email"
                  :rules="emailRules"
                  :label="$t('common.emailRequired')"
                  required
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-card-title>
                  {{ $t("users.authorizedDealers") }}
                </v-card-title>
                <v-data-table
                  dense
                  :headers="headers"
                  :items="getDealers"
                  hide-default-footer
                  fixed-header
                  :items-per-page="-1"
                  :height="5 * 48"
                  :mobile-breakpoint="0"
                >
                  <template #item.actions="{ item }">
                    <base-button icon @click="() => revoke(item)">
                      <v-icon>mdi-delete</v-icon>
                    </base-button>
                  </template>

                  <template #item.active="{ item }">
                    <base-checkbox
                      :value="item.active"
                      @changed="(active) => update(item, active)"
                    />
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <base-button outlined text @click="dialog = false">
            {{ $t("common.close") }}
          </base-button>
          <v-spacer />
          <base-button :disabled="!valid" :loading="loading" type="submit">
            {{ $t("users.invite") }}
          </base-button>
        </v-card-actions>
      </v-form>
    </base-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import BaseCheckbox from "@components/base/BaseCheckbox.vue";

export default {
  name: "ManageDealersDialog",
  components: { BaseCheckbox },
  data() {
    return {
      dialog: false,
      valid: false,
      loading: false,
      email: "",
      emailRules: [
        (v) =>
          !!v ||
          this.$t("error.isRequired", { field: this.$t("common.email") }),
      ],
      headers: [
        { text: this.$t("common.email"), value: "email", sortable: false },
        { text: this.$t("common.name"), value: "name", sortable: false },
        { text: this.$t("common.active"), value: "active", sortable: false },
        { text: this.$t("common.actions"), value: "actions", sortable: false },
      ],
    };
  },
  computed: {
    ...mapGetters("invitations", ["getDealers"]),
    ...mapGetters("auth", ["getIsProvidingService"]),
  },
  methods: {
    async validate() {
      this.loading = true;
      this.$refs.form.validate();
      await this.$store.dispatch("invitations/inviteDealer", this.email);
      this.loading = false;
    },
    async revoke(dealer) {
      this.loading = true;
      await this.$store.dispatch("invitations/revokeDealer", dealer);
      this.loading = false;
    },
    async update(dealer, active) {
      this.loading = true;
      dealer.active = active;
      await this.$store.dispatch("invitations/updateDealer", dealer);
      this.loading = false;
    },
  },
};
</script>

<style scoped></style>
