<template>
  <LayoutDefault>
    <invitation-overview-portrait
      v-if="$vuetify.breakpoint.smAndDown"
      :userInvites="getUserInvites"
      :readonly="getIsProvidingService"
    />
    <invitation-overview-desktop
      v-else
      :userInvites="getUserInvites"
      :readonly="getIsProvidingService"
    />
  </LayoutDefault>
</template>

<script>
import InvitationOverviewDesktop from "./InvitationOverviewDesktop";
import InvitationOverviewPortrait from "./InvitationOverviewPortrait";
import { mapGetters } from "vuex";

export default {
  name: "InvitationOverview",
  components: {
    InvitationOverviewPortrait,
    InvitationOverviewDesktop,
  },
  computed: {
    ...mapGetters("invitations", ["getUserInvites"]),
    ...mapGetters("auth", ["getIsProvidingService"]),
  },
  created() {
    this.$store.dispatch("invitations/init");
    this.$store.dispatch("invitations/initializeDealers");
  },
};
</script>

<style lang="scss" scoped></style>
