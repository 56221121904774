<template>
  <v-dialog v-model="dialog" persistent scrollable width="450" @click.stop>
    <base-card>
      <v-container>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="text-h5">
              {{ user.email }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-form autocomplete="on">
          <v-row>
            <v-col cols="10">
              <v-list-item
                v-ripple="{ class: `primary--text` }"
                :disabled="readonly"
                dense
                @click="active = !active"
              >
                <v-list-item-action>
                  <base-checkbox v-model="active" :disabled="readonly" />
                </v-list-item-action>

                <v-list-item-title class="text-subtitle-2">
                  {{ $t("common.active") }}
                </v-list-item-title>
              </v-list-item>
            </v-col>
            <v-col cols="2">
              <v-tooltip top v-bind="tooltipBehaviour">
                <template #activator="{ on }">
                  <base-button icon v-on="on">
                    <v-icon>mdi-information-variant</v-icon>
                  </base-button>
                </template>
                {{ $t("users.activeDescription") }}
              </v-tooltip>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="10">
              <v-list-item
                v-ripple="{ class: `primary--text` }"
                :disabled="
                  (editRole && editRole.level && editRole.level === 900) ||
                  readonly
                "
                dense
                @click="edit = !edit"
              >
                <v-list-item-action>
                  <base-checkbox v-model="edit" :disabled="readonly" />
                </v-list-item-action>

                <v-list-item-title class="text-subtitle-2">
                  {{ $t("common.edit") }}
                </v-list-item-title>
              </v-list-item>
            </v-col>
            <v-col cols="1">
              <v-tooltip top v-bind="tooltipBehaviour">
                <template #activator="{ on }">
                  <base-button icon v-on="on">
                    <v-icon>mdi-information-variant</v-icon>
                  </base-button>
                </template>
                {{ $t("users.editDescription") }}
              </v-tooltip>
            </v-col>
          </v-row>

          <v-row class="ma-2" no-gutters>
            <v-select
              v-model="editRole"
              :items="availableRoles"
              :label="$t('common.role')"
              :menu-props="{ maxHeight: 608 }"
              :readonly="readonly"
              hide-details
              item-text="displayName"
              return-object
            />
          </v-row>

          <v-row class="ma-2" no-gutters>
            <v-col>
              <base-multi-select
                v-model="selectedDevices"
                :available-items="getAllOwnedDevices"
                :readonly="readonly"
                :text="$t('common.devices')"
              />
            </v-col>
          </v-row>

          <v-row class="ma-2" no-gutters>
            <v-col>
              <base-multi-select
                v-model="selectedScreenGroups"
                :available-items="getAllOwnedScreenGroups"
                :readonly="readonly"
                :text="$t('common.customScreens')"
              />
            </v-col>
          </v-row>
        </v-form>
        <v-card-actions class="mt-4">
          <base-button
            :disabled="loading"
            class="px-4"
            outlined
            text
            @click="dialog = false"
          >
            {{ $t("common.cancel") }}
          </base-button>
          <v-spacer />
          <base-button
            :disabled="readonly"
            :loading="loading"
            width="108px"
            @click.stop="submit"
          >
            {{ confirmButtonText }}
          </base-button>
        </v-card-actions>
      </v-container>
    </base-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { notifyUserTranslation } from "@error/helpers";
import { roles } from "@utils/permissions";

export default {
  name: "InvitationPermissionDialog",
  props: {
    user: {
      type: Object,
      required: false,
      default: null,
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
    isNew: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      dialog: true,
      loading: false,
      active: true,
      edit: false,
      editRole: roles.getRoleByValue(900),
      selectedDevices: [],
      selectedScreenGroups: [],
    };
  },
  computed: {
    ...mapGetters(["getAllOwnedDevices", "getAllOwnedScreenGroups"]),
    availableRoles() {
      return roles.getAvailableRoles(300);
    },
    confirmButtonText() {
      return this.isNew ? this.$t("users.invite") : this.$t("common.save");
    },
    tooltipBehaviour() {
      return {
        "open-on-click": this.$vuetify.breakpoint.smAndDown,
        //hover needs to be false explicitly
        "open-on-hover": !this.$vuetify.breakpoint.smAndDown,
      };
    },
  },
  methods: {
    initForm() {
      if (this.user.hasOwnProperty("role")) {
        this.editRole = roles.getRoleByValue(this.user.role);
      }
      if (this.user.hasOwnProperty("active")) {
        this.active = this.user.active;
      }
      this.edit = this.user.edit;
    },
    async submit() {
      const userEdited = {
        email: this.user.email,
        active: this.active,
        edit: this.edit,
        role: this.editRole,
        devices: this.selectedDevices.map((d) => d.id),
        customScreens: this.selectedScreenGroups.map((s) => s.Id),
      };

      userEdited.role = userEdited.edit
        ? userEdited.role.level
        : userEdited.role.withoutEdit;

      this.loading = true;

      try {
        await this.$store.dispatch("invitations/updateUser", userEdited, {
          root: true,
        });
        const label = this.isNew ? "users.inviteSent" : "users.updated";
        notifyUserTranslation(label, "success", { email: userEdited.email });
        this.$emit("success");
      } catch (err) {
        notifyUserTranslation("users.somethingWrong", "error", {
          email: userEdited.email,
        });
      } finally {
        this.dialog = false;
        this.loading = false;
      }
    },
  },
  watch: {
    dialog() {
      this.$emit("input", this.dialog);
    },
    editRole(newRole) {
      if (newRole?.level === 900) {
        this.edit = false;
      }
    },
  },
  created() {
    this.initForm();
    this.selectedDevices = this.getAllOwnedDevices.filter((d) =>
      this.user?.deviceIds?.includes(d.id)
    );
    this.selectedScreenGroups = this.getAllOwnedScreenGroups.filter((s) =>
      this.user?.customScreenIds?.includes(s.Id)
    );
  },
};
</script>

<style scoped></style>
