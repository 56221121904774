<template>
  <base-renderless-dialog>
    <template #default="{ openDialog }">
      <base-button :disabled="disabled" icon @click.stop="openDialog(user)">
        <v-icon>mdi-delete</v-icon>
      </base-button>
    </template>

    <template #dialog="{ data, listeners }">
      <base-confirm-dialog
        :accept-function="deleteUser"
        :item="data"
        v-on="listeners"
      >
        <template #title>
          {{ $t("users.deleteUser") }}
        </template>
        <template #subTitle="{ item }">
          <p>
            {{ $t("users.deleteUserExplanation") }}
          </p>
          <p class="font-weight-bold">
            {{ $t("common.noUndone") }}
          </p>
          <p>{{ item.email }}</p>
        </template>
      </base-confirm-dialog>
    </template>
  </base-renderless-dialog>
</template>

<script>
export default {
  name: "DeleteInviteDialog",
  props: {
    user: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    deleteUser() {
      this.$store.dispatch("invitations/deleteUser", this.user, { root: true });
    },
  },
};
</script>

<style scoped></style>
