<template>
  <base-renderless-dialog>
    <template #default="{ openDialog }">
      <base-card>
        <v-toolbar color="gray" flat>
          <v-toolbar-title>
            {{ $t("common.users") }}
          </v-toolbar-title>
          <v-spacer />

          <base-checkbox
            :value="getAllowServiceAccess"
            :disabled="readonly"
            class="mt-5"
            @changed="toggleServiceAccess"
          />
          <span class="me-4"> {{ $t("users.allowStienenAccess") }}</span>
          <manage-dealers-dialog />
          <invite-user-dialog :disabled="readonly" />
        </v-toolbar>

        <v-divider />

        <v-data-table
          :headers="headers"
          :items="userInvites"
          :loading="isLoading"
          :loading-text="$t('common.loading')"
          sort-by="email"
          @click:row="openDialog"
          dense
        >
          <template #item.email="{ item }">
            <h4>{{ item.email }}</h4>
          </template>

          <template #item.role="{ value }">
            <v-icon>{{ getIcon(value) }}</v-icon>
            {{ getRoleName(value) }}
          </template>

          <template #item.edit="{ item }">
            <v-icon>
              {{ invitationEditIcon(item) }}
            </v-icon>
          </template>

          <template #item.active="{ value }">
            <v-icon :color="value ? 'primary' : 'error'">
              {{ invitationStatusIcon(value) }}
            </v-icon>
            <span :class="invitationStatusClass(value)">
              {{ invitationStatusLabel(value) }}
            </span>
          </template>

          <template #item.invitationValid="{ value }">
            <v-icon :color="value ? 'primary' : 'warning'">
              {{ invitationValidIcon(value) }}
            </v-icon>
            <span :class="invitationValidClass(value)">
              {{ invitationValidLabel(value) }}
            </span>
          </template>

          <template #item.actions="{ item }">
            <base-button icon @click="openDialog(item)">
              <v-icon>mdi-pencil</v-icon>
            </base-button>

            <delete-invite-dialog :disabled="readonly" :user="item" />
            <resend-invite-dialog
              v-if="!item.invitationValid"
              :disabled="readonly"
              :user="item"
            />
          </template>

          <template #no-data>
            <span class="text-body-1"> {{ $t("users.noInvitations") }}</span>
            <br />
            <span class="text-body-1">
              {{ $t("users.noInvitationsExplanation") }}
            </span>
          </template>
        </v-data-table>
      </base-card>
    </template>

    <template #dialog="{ data, listeners }">
      <invitation-permission-dialog
        :user="data"
        :readonly="readonly"
        v-on="listeners"
      />
    </template>
  </base-renderless-dialog>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { roles } from "@utils/permissions";
import InviteUserDialog from "./dialogs/InviteUserDialog";
import InvitationPermissionDialog from "./dialogs/InvitationPermissionDialog";
import DeleteInviteDialog from "@components/admin/invitations/dialogs/DeleteInviteDialog";
import ManageDealersDialog from "@components/admin/invitations/dialogs/ManageDealersDialog";
import ResendInviteDialog from "@components/admin/invitations/dialogs/ResendInviteDialog";

export default {
  name: "InvitationOverviewDesktop",
  components: {
    ResendInviteDialog,
    ManageDealersDialog,
    DeleteInviteDialog,
    InviteUserDialog,
    InvitationPermissionDialog,
  },
  props: {
    userInvites: {
      type: Array,
      required: true,
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      headers: [
        {
          text: this.$t("common.email"),
          align: "left",
          sortable: false,
          value: "email",
        },
        {
          text: this.$t("common.role"),
          sortable: false,
          value: "role",
        },
        {
          text: this.$t("common.edit"),
          value: "edit",
        },
        {
          text: this.$t("common.active"),
          value: "active",
        },
        {
          text: this.$t("users.invitationStatus"),
          value: "invitationValid",
        },
        {
          text: this.$t("common.actions"),
          sortable: false,
          value: "actions",
        },
      ],
    };
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.loading,
    }),
    ...mapGetters("auth", ["getAllowServiceAccess"]),
  },
  methods: {
    async toggleServiceAccess(value) {
      await this.$store.dispatch("auth/setServiceAccess", value);
    },
    getRoleName(role) {
      return roles.getRoleByValue(role).displayName;
    },
    getIcon(role) {
      return roles.getRoleByValue(role).icon;
    },
    invitationEditIcon(invitation) {
      return invitation.edit ? "mdi-pencil-outline" : "mdi-pencil-off-outline";
    },
    invitationStatusLabel(active) {
      return active ? this.$t("common.active") : this.$t("common.blocked");
    },
    invitationStatusIcon(active) {
      return active
        ? "mdi-account-check-outline"
        : "mdi-account-cancel-outline";
    },
    invitationStatusClass(active) {
      return active ? "primary-text" : "error-text";
    },
    invitationValidLabel(valid) {
      return valid ? this.$t("common.active") : this.$t("common.pending");
    },
    invitationValidIcon(valid) {
      return valid ? "mdi-email-check-outline" : "mdi-email-alert-outline";
    },
    invitationValidClass(valid) {
      return valid ? "primary-text" : "warning-text";
    },
  },
};
</script>

<style lang="scss" scoped>
.primary-text {
  color: var(--v-primary-base);
}

.error-text {
  color: var(--v-error-base);
}

.warning-text {
  color: var(--v-warning-base);
}
</style>
