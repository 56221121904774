<template>
  <base-renderless-dialog>
    <template #default="{ openDialog }">
      <base-button :disabled="disabled" icon @click.stop="openDialog(user)">
        <v-icon color="warning"> mdi-email-sync-outline </v-icon>
      </base-button>
    </template>

    <template #dialog="{ data, listeners }">
      <base-confirm-dialog
        :accept-function="resendInvite"
        :item="data"
        v-on="listeners"
      >
        <template #title>
          {{ $t("users.resendInvite") }}
        </template>
        <template #subTitle="{ item }">
          <p>
            {{ $t("users.resendInviteExplanation") }}
          </p>
          <p>{{ item.email }}</p>
        </template>
      </base-confirm-dialog>
    </template>
  </base-renderless-dialog>
</template>

<script>
import { notifyUserTranslation } from "@error/helpers";

export default {
  name: "ResendInviteDialog",
  props: {
    user: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    async resendInvite() {
      try {
        await this.$store.dispatch("invitations/resendInvite", this.user, {
          root: true,
        });
        notifyUserTranslation("users.resendSuccess", "success");
      } catch (err) {
        notifyUserTranslation("users.resendFailed", "error");
      }
    },
  },
};
</script>

<style scoped></style>
