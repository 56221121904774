<template>
  <v-card class="fill-height" tile>
    <portal to="navBarEnd">
      <invite-user-dialog :disabled="readonly" />
    </portal>

    <base-renderless-dialog>
      <template #default="{ openDialog }">
        <v-list>
          <template v-for="(item, index) in userInvites">
            <v-list-item
              :key="item.Id"
              :class="{ 'my-2': item.Active }"
              @click.stop="openDialog(item)"
            >
              <template #default>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ item.email }}
                  </v-list-item-title>

                  <v-list-item-subtitle>
                    {{ getRoleName(item.role) }}
                  </v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-action>
                  <v-list-item-action-text
                    v-if="!item.active"
                    class="red--text"
                  >
                    {{ $t("common.blocked") }}
                  </v-list-item-action-text>

                  <v-row no-gutters>
                    <v-col>
                      <base-button icon @click.stop="openDialog(item)">
                        <v-icon>mdi-pencil</v-icon>
                      </base-button>
                    </v-col>

                    <v-col>
                      <delete-invite-dialog :disabled="readonly" :user="item" />
                    </v-col>
                  </v-row>
                </v-list-item-action>
              </template>
            </v-list-item>

            <v-divider v-if="index + 1 < userInvites.length" :key="index" />
          </template>
        </v-list>
      </template>

      <template #dialog="{ data, listeners }">
        <invitation-permission-dialog
          :user="data"
          :readonly="readonly"
          v-on="listeners"
        />
      </template>
    </base-renderless-dialog>
  </v-card>
</template>

<script>
import InvitationPermissionDialog from "./dialogs/InvitationPermissionDialog";
import { roles } from "@utils/permissions";
import DeleteInviteDialog from "@components/admin/invitations/dialogs/DeleteInviteDialog";
import InviteUserDialog from "@components/admin/invitations/dialogs/InviteUserDialog";

export default {
  name: "InvitationOverviewPortrait",
  components: {
    DeleteInviteDialog,
    InvitationPermissionDialog,
    InviteUserDialog,
  },
  props: {
    userInvites: {
      type: Array,
      required: true,
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    getRoleName(role) {
      return roles.getRoleByValue(role).displayName;
    },
  },
};
</script>

<style scoped></style>
